import React, { useState, useEffect } from 'react';
import { IconButton, Snackbar } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import EventEmitter from '../../../lib/eventEmitter';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    close: {
      padding: theme.spacing(0.5),
    },
    commonBackground: {
      backgroundColor: 'grey',
    },
    errorBackground: {
      backgroundColor: 'red',
    },
    successBackground: {
      backgroundColor: 'green',
    },
  }),
);

export function SnackBarNotification(props: any) {
  const classes = useStyles(props);
  const [state, setState] = useState({
    message: undefined,
    variant: undefined,
    showNotification: false,
  });
  const _handleResponse = (response: any) => {
    setState({
      message: response.responseMessage,
      variant: response.responseStatus,
      showNotification: true,
    });
  };

  useEffect(() => {
    EventEmitter.on('showNotification', _handleResponse);
    return () => {
      EventEmitter.removeListener('showNotification', _handleResponse);
    };
  });

  const hideNotification = () => {
    setState({
      message: undefined,
      variant: undefined,
      showNotification: false,
    });
  };

  const handleClose = () => {
    hideNotification();
  };

  const background =
    state.variant === 'success'
      ? classes.successBackground
      : state.variant === 'error'
      ? classes.errorBackground
      : classes.commonBackground;
  return (
    <Snackbar
      color="green"
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom',
      }}
      ContentProps={{
        classes: { root: background },
      }}
      open={state.showNotification}
      autoHideDuration={3000}
      onClose={handleClose}
      message={state.message}
      action={[
        // tslint:disable-next-line: jsx-wrap-multiline
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          className={classes.close}
          onClick={handleClose}
        >
          <Close />
        </IconButton>,
      ]}
    />
  );
}

export default SnackBarNotification;
